import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { NewsletterForm } from "../components/newsletter"
import { StaticImage } from "gatsby-plugin-image"

const ContentContainer = styled.main`
    max-width: 1200px;
    margin: 0 auto;
    direction: rtl;
    line-height: 4rem;
    a {
        color: #FFF;
    }
    @media screen and (max-device-width: 800px) {
        padding: 0 1rem;
        text-align: center;
    }
`
const ThumbnailContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 20% 80%;
    grid-template-rows:  1fr;
    margin-bottom: 20px;
    @media screen and (max-device-width: 800px) {
        grid-template-columns: 50% 50%;
    }
    img {
        height: auto;
        margin-block-start: 1em;
        margin-block-end: 1em;
        width: 90%;
    }
    p {
        width: 90%;
        line-height: 3rem;
    }
    span {
        margin-bottom: 25px;
        display: inline-block;
    }
`

const IndexPage = ({ data }) => ( 
    <Layout>
        <Seo title="تحرر  - Unleaving" />
        <ContentContainer>
        <h2><span className="font-secondary">Unleaving </span>تحرر </h2>
        <p>وهي متوفره الان على موقع <span className="font-secondary"><a href="https://store.steampowered.com/app/1076720/Unleaving/" >Unleaving Steam</a></span>  اسم اللعبه 
        تتلخص حكاية اللعبه حول طفل صغير , وعندما يبدو كل شيء ميؤوسا منه , يستيقظ الطفل على همسات الورقه الاخيره وينجذب الى عالم خيالي.
        </p>
        <p>
        ان لعبة التحررهي لعبة الغاز ذات قصه انسانيه , تتضمن تحديات وكل تحد منها يجري داخل منظر طبيعي مرسوم يدويا بمهاره عاليه ,كما ان  جميع المعاني فيها تسرد من خللال الفن المتقن والشعر.
        </p>
        <a
            href="https://www.youtube.com/watch?v=ykVY1xtq0rg"
            target="_blank"
            rel="noopener noreferrer"
        >
        <StaticImage
            className="image"
            src="../assets/images/assets-unleaving-awake-1-chapter.png"
            alt="Solitary reflection"
            layout="fullWidth"
            placeholder="blurred"
            loading="eager"
        />
        </a>
        <p>مواصفات لعبة التحرر :                                                           
        </p>
        <ul>
            <li> استكشاف : اخذت اللعبه عنوانها من قصيدة الشاعر الانكليزي جيرارد مانلي هوبكنز(الربيع والخريف لطفل صغير)</li>
            <li>تفتتح اللعبه بعبارة :ماركريت هل انت حزينه على رحيل كولدن كروف؟
        وماركريت هي الطفله الصغيره ,وكولدن كروف اسم رمزي يعبر عن السلام والجمال,حيث يعبر الشاعر هوبكنز عن تغير الفصول حيث تفقد كولدن كروف اوراقها , كرمز لدورة الحياة </li>
            <li>ان لعبة التحرر هي لعبه مجازيه, حيث تم تصميمها من خلال الرسوم التوضيحيه وسياقات الالغاز</li>
        </ul>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/ykVY1xtq0rg?si=5KHDEYaLJFFGDki-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>تجربه فنيه :</p>
        <ul>
            <li>
        - تعد لعبة التحررلعبة فيديو ذات سلسله واسعه من اللوحات والرسومات المتقنه الجميله ومتعددة الوسائط .فكل اطار فيها هو عباره عن رسم يدوي يدمج بسلاسه وتناغم عناصر الوان الاكرايلك وقلم الرصاص والكرافيت     
            </li>
            <li>تنبض الصور المرئيه فيها بالحياة من خلال الرسوم المتحركه التقليديه مع الالتزام بالاصاله.وقد تم رسم كل مشهد فيها من جديد اي بدون تكرار.</li>
            <li>يعتمد كل فصل فيها على موضوع محدد, وبالتالي تتأثر الالوان والاسلوب وفقا لسياقات الحاله والعواطف ومجريات القصه , حيث يمكن تحسس ذلك من خلال الانغماس باللعبه والاستمتاع بالمشاهد ذات الفن الرفيع النابض بالحياة ومتابعة مجريات القصه </li>
        </ul>


        <iframe width="100%" height="315" src="https://www.youtube.com/embed/wyGeF4UYw6k?si=ZFJla37gqGv0JkSH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>الالغاز في محور القصه:</p>
        <ul>
            <li>من خلال طريقة اللعب التي تتميز بالالغاز والتحديات القائمه على المهارات والتي ترتبط كل واحده منها باحكام بنسيج القصه ,لذا فان اللاعبين مدعوون للانغماس في هذه التجربه الفريده والممتعه.</li>
            <li>من خللال الموقع التالي  يمكنك الحصول على لعبة التحرر <a className="font-secondary btn" href="https://store.steampowered.com/app/1076720?utm_source=source">link</a></li>
        </ul>

    <StaticImage
        className="image"
        src="../assets/images/localization/arabic-unleaving-twin-level-chapter-screenshot-translation.png"
        alt="unleaving screenshot in Arabic"
        layout="fullWidth"
        placeholder="blurred"
        loading="eager"
    />
    <p><i> تعليق الصورة - ترجمة الشعر الى اللغة العربية ـ ابتسام رومي  </i><span className="font-secondary"></span></p>
        <h2>الحرفيين</h2>
        <p>تجمع <span className="font-secondary">Unleaving</span> عدد من المبتكرين الدائمين جنبًا إلى جنب مع المتعاونين الذين اجتمعوا معًا في أوقات مختلفة. كل واحد ذو موهبة فريدة </p>
        <h2>المبتكرون</h2>
        <ThumbnailContainer>
            <StaticImage src='../assets/images/collaborator/sura-saif-unleaving-creators-indie-video-game-devs-couple.jpg'/>
            <p>
            <span>الثنائي الديناميكي ،الزوجة والزوج وجدا "orangutan matter"، سرى كرناوي  وسيف جبر. لقد عملا على كتابة اللعبة والتطوير و الرسم والإنتاج. مستوحا من حبهم للفن و تاثرهم بألعاب الألغاز و بالذات لعبة الفيديو المعروفة "Limbo". </span>
            <span>كونهما من العراق، عانيا من الاغتراب بعد الحرب والتشريد، تماما مثل دائرتهم من العائلة والأصدقاء. الاغتراب تجربة إنسانية شائعة. كانت الفكرة أنهما يرغبان في استكشاف أسئلة أعمق حول الحياة ولحظات الضعف من خلال منظور الطفل لاستكشاف العالم من حوله.</span>
            <span>"orangutan matter"،  وهو استوديو لألعاب الفيديو المستقلة مقره في تورونتو. حاول كلاهما توظيف ألعاب الفيديو لتكون وسيلة ثقافية، وسيلة للتعبير عن أعماق المشاعر الإنسانية. يدور عملهم الإبداعي حول تطوير السرد الحر، مما يسمح لمجموعة متنوعة من الآراء بتشكيل عمل موحدًا.</span>
            </p>
        </ThumbnailContainer>


        <h2><span className="font-secondary">المتعاونون</span></h2>
            <p>    كانو المتعاونون في "Unleaving" مدفوعون بالالتزام بالأصالة و الإبداع النقي في مجال تطوير ألعاب الفيديو المستقلة.</p>
            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/diala-brisly-portrait-unleavingcollaborator.jpeg'/>
                <p>ديالا بريصلي  - فالانس، فرنسا: فنانة شخصيات Unleaving</p>

            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/Jayson-jayson-fuerstengberg-photo-unleaving-collaborator.jpg'/>
                <p> جيسون فورستنبرج 
جزيرة فانكوفر, كندا-هو فنان القلم الرصاص(الكرافايت)في اللعبه , حيث ادت لمساته الفنيه الى بث الحيويه في الرسوم التوضيحيه الجميله للبيئه والالغاز.</p>
            </ThumbnailContainer>


            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/francois-messier-unleaving-collaborator.jpg'/>
                <p>فرانسوا ميسييه - مونتريال، كيبيك: بخبرته التي تزيد عن 16 عامًا كمصمم  مراحل، كانت خبرته وصبره حاسمين في الحفاظ على رؤية لعبة "Unleaving</p>
            </ThumbnailContainer>


            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/asmaa al-issa_portrait-unleaving-collaborator.jpg'/>
                <p>
أسماء العیسى (موالید بغداد، العراق) ھاجرت الى موھكنستسس/كالجري مع عائلتھا عام ٢٠٠١ وحصلت على جنسیة كندیة في عام ٢٠٠٥. هي مستشارة في فن الرسم و اخذت دور حيويا في تأسيس و وضع القواعد للفنية للعبة "Unleaving".</p>
            </ThumbnailContainer>


            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/macy-kuang-miaomiao-games-unleaving collaborator.png'/>
                <p>ميسي كوانغ - تورونتو، كندا: تجلب ميسي، مطورة ألعاب، مهاراتها الواسعة إلى "Unleaving"، حيث تُسهم في الهندسة واتخاذ القرارات التقنية بجانب سرى وسيف. </p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/corina-diaz-unleaving-collaborator-communication-manager.jpg'/>
                <p>كورينا دياز , أونتاريو، كندا: استشارية في استراتيجية، التسويق، وإدارة المجتمع. ساهمت في صياغة التواصل الأصيل لـ "Unleaving".</p>
            </ThumbnailContainer>

            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/alicia-enstorm-headshot-unleaving-collaborator.jpg'/>
                <p>أليشيا إنستورم -   عازفة كمان استخدمت موسيقتها مقاطع من موسيقتها في "Unleaving". </p>
            </ThumbnailContainer>
            <h4><span className="font-secondary">تقدير خاص</span></h4>
            <ThumbnailContainer>
                <StaticImage src='../assets/images/collaborator/osama-dorias-black-and-white-photo.jpg'/>
                <p>أسامة درياز , مونتريال، كندا: المرشد - يأتي أسامة بخبرة واسعة من Blizzard Entertainment، وهو أستاذ في كلية داوسون . افادة خبرته بتوجيه سرى وسيف على إنشاء عالم متجانس في تصميم لعبة "Unleaving". بالإضافة إلى ذلك دعمهم كمطوري ألعاب عراقيين، معترفًا بمواهبهم وقدراتهم.</p>
            </ThumbnailContainer>
            <ThumbnailContainer>
                <StaticImage src='../assets/images/localization/arabic-unleaving-twin-level-chapter-screenshot.jpg'/>
                <p>أبتسام الرومي، السويد: نود أن نعبر عن شكرنا الخاص لترجمة الشعر في لعبة التحرر إلى اللغة العربية من قبل مدرسة العربي القديرة أبتسام الرومي.</p>
            </ThumbnailContainer>


    <StaticImage
        className="image"
        src="../assets/images/assets_unleaving_video_game_puzzle_platformer_art_orangutan_matter_behind-the-scenes.jpeg"
        alt="behind the scenes - the making of Unleaving by sura Karnawi - hand painted art"
        layout="fullWidth"
        placeholder="blurred"
        loading="eager"
    />
    <p><i> تعليق الصورة: خلف الكواليس في صناعة لعبة  </i><span className="font-secondary">Unleaving</span></p>
    <NewsletterForm />
</ContentContainer>
</Layout>
)

export default IndexPage

export const query = graphql`
    query {
        posterText: file(relativePath: { eq: "text-unleaving-title.png" }) {
            childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
            }
            }
        }
    }
`